<template>
    <mercur-dialog :is-open.sync="dialogActive">
        <div slot="header"><h2 class="font-weight-normal">Attribute mapping</h2></div>
        <div slot="default" class="full-height-layout">
            <p v-if="attributeMapParams">Please add the mapping for <strong>{{attributeMapParams.supplierIntegrationAttributeName}}</strong> - <strong>{{attributeMapParams.supplierIntegrationAttributeOption}}</strong></p>
            <div class="mercur-attribute-sets">
                <div class="mercur-attribute-set"
                     v-for="(mappedAttribute, key) in mappedAttributes"
                     :key="mappedAttribute.iterationKey"
                >
                    <mercur-attribute-selector
                        v-model="mappedAttributes[key]"
                        :attributes="attributes"
                        :initial-attribute-name="mappedAttribute.attributeName"
                        :initial-attribute-option="mappedAttribute.attributeOption"
                    ></mercur-attribute-selector>
                    <mercur-button class="btn btn-icon" @click="removeMercurAttribute(key)">
                        <i class="fas fa-trash"></i>
                    </mercur-button>
                </div>
            </div>
            <mercur-button class="btn btn-icon btn-raised" @click="addMercurAttribute">
                <i class="fas fa-plus"></i>
            </mercur-button>
        </div>
        <div slot="footer">
            <mercur-button class="btn btn-raised btn-yellow text-uppercase" @click="$emit('update:dialogActive', false)">Close</mercur-button>
            <mercur-button class="btn btn-raised btn-yellow text-uppercase" :disabled="loading" @click="saveAttributeMapping">Save</mercur-button>
        </div>
        <mercur-progress-bar indeterminate v-if="loading"></mercur-progress-bar>
    </mercur-dialog>
</template>

<script>
import CONFIG from '@root/config'
import MercurAttributeSelector from '@/components/attributes/MercurAttributeSelector'

export default {
    name: 'SupplierIntegrationAttributeMapDialog',
    components: { MercurAttributeSelector },
    props: ['dialogActive', 'mappedAttributes', 'attributeMapParams'],
    data () {
        return {
            attributes: null,
            loading: false,
        }
    },
    methods: {
        saveAttributeMapping () {
            this.$emit('saveAttributeMapping', {
                ...this.attributeMapParams,
                mappedAttributes: this.mappedAttributes,
            })
        },
        addMercurAttribute () {
            this.mappedAttributes.push({
                iterationKey: new Date().getTime(),
            })
        },
        removeMercurAttribute (key) {
            this.mappedAttributes.splice(key, 1)
        },
    },
    created () {
        const url = CONFIG.API.ROUTES.PRODUCTS.GENERATION.ATTRIBUTES.LIST
        this.addJob(url)
        this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            this.attributes = data.data.items.flatMap(attribute => attribute.attributeName)
        }).finally(() => {
            this.finishJob(url)
        })
    },
}
</script>
