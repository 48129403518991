<template>
    <mercur-card class="fill full-height-layout mx-4 mb-3">
        <grid-header :quickSearch.sync="filters.search">
            Attribute mapping for {{supplierName}} Supplier Integration
        </grid-header>

        <data-table
            class="fill full-height-layout border"
            :options="options"
            :url="url"
            :quickSearch="filters.search"
            ref="grid"
        ></data-table>
        <div class="flex justify-content-end">
            <mercur-button class="btn btn-raised btn-yellow text-uppercase mt-2" :to="getProductReviewRoute()">Continue</mercur-button>
        </div>
        <supplier-integration-attribute-map-dialog
            :dialog-active.sync="attributeMapDialogOpen"
            :mapped-attributes="attributeMapDialogParams ? attributeMapDialogParams.mappedAttributes : null"
            :attribute-map-params="attributeMapDialogParams"
            @saveAttributeMapping="saveAttributeMapping"
            ></supplier-integration-attribute-map-dialog>
    </mercur-card>
</template>

<script>
import DataTable from '@/components/DataTable'
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'
import SupplierIntegrationAttributeMapDialog
    from '../../components/supplier_integrations/SupplierIntegrationAttributeMapDialog'

export default {
    name: 'SupplierIntegrationAttributeMapping',
    components: { SupplierIntegrationAttributeMapDialog, DataTable, GridHeader },
    props: ['supplierIntegrationConfig', 'supplierName'],
    data () {
        return {
            attributeMapDialogOpen: false,
            attributeMapDialogParams: null,
            attributeMap: {},
            options: {
                columns: {
                    'Integration attribute name': {
                        field: 'supplierIntegrationAttributeName',
                        sortable: true,
                    },
                    'Integration attribute option': {
                        field: 'supplierIntegrationAttributeOption',
                        sortable: true,
                    },
                    'Mapping': {
                        field: 'mappedAttributes',
                        sortable: true,
                        cellClass: 'ag-grid__cell--multi-line',
                        autoHeight: true,
                        valueGetter: ({ data }) => {
                            const localValue = this.attributeMap[data.supplierIntegrationAttributeId]
                            if (localValue) {
                                return localValue
                            }
                            return data.mappedAttributes || []
                        },
                        valueFormatter: ({ value }) => {
                            if (!value) {
                                return ''
                            }
                            return value.flatMap(({ attributeName, attributeOption }) => {
                                return `${attributeName}: ${attributeOption}`
                            }).join(`\n`)
                        },
                    },
                },
                getRowNodeId: data => data.supplierIntegrationAttributeId,
                actions: [
                    {
                        icon: 'fas fa-edit',
                        onClick: params => {
                            this.openAttributeMapDialog(params.data)
                        },
                    },
                ],
                enableRangeSelection: true,
                quickSearchColumns: ['supplierIntegrationAttributeName', 'supplierIntegrationAttributeOption'],
                sortModel: [
                    {
                        colId: 'supplierIntegrationAttributeName',
                        sort: 'asc',
                    },
                    {
                        colId: 'supplierIntegrationAttributeOption',
                        sort: 'asc',
                    },
                ],
            },
            url: CONFIG.API.ROUTES.SUPPLIER_INTEGRATIONS.GET_ATTRIBUTES.replace('{supplierIntegrationConfigId}', this.$route.params.supplierIntegrationConfigId),
            filters: {
                search: '',
            },
        }
    },
    methods: {
        openAttributeMapDialog (params) {
            params.mappedAttributes = (params.mappedAttributes || [{}]).map((attribute, key) => {
                attribute.iterationKey = new Date().getTime() - key

                return attribute
            })
            this.attributeMapDialogParams = params
            this.attributeMapDialogOpen = true
        },
        saveAttributeMapping (params) {
            this.attributeMapDialogOpen = false
            this.saveAttributeMappings([params])
        },
        saveAttributeMappings (params) {
            params.forEach(mapping => {
                this.attributeMap[mapping.supplierIntegrationAttributeId] = mapping.mappedAttributes
            })
            this.$refs.grid.gridApi.redrawRows()
            this.addJob(params)
            const url = CONFIG.API.ROUTES.SUPPLIER_INTEGRATIONS.ATTRIBUTE_MAPPING.UPDATE.replace('{supplierIntegrationConfigId}', this.$route.params.supplierIntegrationConfigId)

            this.$api.post(url, params).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Saving attribute mapping completed.`,
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Saving attribute mapping failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(params)
            })
        },
        getProductReviewRoute () {
            return {
                name: 'SupplierIntegrationProductReview',
                params: {
                    ...this.$route.params,
                },
            }
        },
    },
}
</script>
